import {useState} from "react";
import EducationSectionViewing from "../../../models/enums/EducationSectionViewing";
import EducationSecton1 from "./EducationSecton1";



export default function AiDocumentCheckerEducationPage() {
    const [sectionViewing, setSectionViewing] = useState<EducationSectionViewing>(EducationSectionViewing.SECTION_1);
    const sections = [{
        name: "Education",
        description: <EducationSecton1/>,
        type: EducationSectionViewing.SECTION_1
    }];

    return (
        <div className={"bg-main-background min-h-screen"}>
            <section
                className={"pb-20 text-center px-8 md:px-32 pt-[60px] md:pt-[90px] bg-white border-b border-main-500"}>
                <h1 className={"text-4xl py-5 font-main-bold"}>
                    Modul educatie
                </h1>
            </section>
            <section className="p-10 text-justify flex flex-col gap-3">
                <h2 className="text-lg font-bold">Procedura de verificare a furnizorilor:</h2>

                <h3 className="text-lg font-bold italic">Info generale</h3>
                <ul className="list-disc pl-5 space-y-2">
                    <li>Verificarea furnizorilor se face, de obicei, cand nu s-a mai lucrat deloc sau de mult timp cu
                        acel furnizor.
                    </li>
                    <li>Atunci cand se face o cursa cu un furnizor cunoscut, se verifica ca asigurarea CMR sa fie
                        valabila si sa acopere conditiile necesare.
                    </li>
                    <li className="font-bold">!ATENTIE!</li>
                    <p>Asko a transmis ca sunt transportatori mari care au o polita de asigurare pt 10 camioane in loc
                        de 30 =&gt; chiar daca furnizorii sunt mari si s-a mai lucrat cu ei, tot pot aparea falsuri.</p>
                </ul>

                <h3 className="text-lg font-bold">Verificarea furnizorilor cu care nu s-a mai lucrat:</h3>
                <ul className="list-disc pl-5 space-y-2">
                    <li>Verificarea nr de telefon care te-a sunat pe bursa de transport.</li>
                    <li>Se cere ID-ul firmei, se cauta firma si daca are nr de tel atasat pe pagina de pe bursa
                        (cautarea se poate face direct si dupa nr).
                    </li>
                </ul>

                <h3 className="text-lg font-bold">Verificarea pe Google a firmei:</h3>
                <ul className="list-disc pl-5 space-y-2">
                    <li>Lista firme (sa aiba cifre bune: cifra afaceri, profit, datorii, active, angajati), pagina web,
                        site ARR.
                    </li>
                </ul>

                <h3 className="text-lg font-bold">Verificarea Documentelor:</h3>
                <ul className="list-disc pl-5 space-y-2">
                    <li>
                        <span className="font-bold">Se solicita urmatoarele documente:</span>
                        <ul className="list-square pl-5 space-y-2">
                            <li>Licenta de transport</li>
                            <li>Asigurarea CMR</li>
                            <li>CUI</li>
                            <li>Buletin administrator/sofer</li>
                            <li>Talon</li>
                        </ul>
                    </li>
                    <li>Pe fiecare document se analizeaza modificari (falsuri) si informatii (OCR).</li>
                </ul>

                <h3 className="text-lg font-bold italic">Asigurarea CMR</h3>
                <ul className="list-disc pl-5 space-y-2">
                    <li>O asigurare CMR este <strong>valabila</strong> daca este de <strong>actualitate</strong>,
                        acopera <strong>tipul de transport</strong>, are <strong>nr auto</strong> scris pe ea si
                        este <strong>platita</strong>.
                    </li>
                    <li>Valabilitate - afisarea datelor si atentionare daca nu este valabila.</li>
                    <li>Suma acoperita - afisarea datelor si atentionare daca nu este acoperitoare.</li>
                    <li>Numar auto - asigurarea trebuie sa aiba nr auto trecut pe ea, daca are serie de sasiu se cere
                        talon sau anexa, daca nu are deloc se cere anexa.
                    </li>
                    <li>Plata - daca este anuala de obicei scrie pe ea ca este platita, daca este
                        lunar/trimestrial/semestrial de obicei se cere dovada platii ratei.
                    </li>
                </ul>

                <p className="text-red-600 font-bold">Daca asigurarea CMR apare ca fiind falsa - cea mai sigura metoda
                    este de a fi validata direct cu asiguratorul.</p>


                <section className={"flex flex-col md:flex-row gap-2"}>
                    <div className={"p-5 bg-white rounded-lg md:w-1/5 m-5 h-fit sticky top-[80px] shadow-lg"}>
                        {
                            sections.map((section, index) =>
                                <p onClick={() => setSectionViewing(section.type)}
                                   className={"p-2 rounded-lg font-bold cursor-pointer " + (sectionViewing === section.type ? "bg-main-500 text-white" : "")}>{section.name}</p>
                            )
                        }
                    </div>
                    <div className={"w-full md:w-4/5"}>
                        {sections.filter((section, index) => section.type === sectionViewing).map((section) => section.description)}
                    </div>
                </section>

            </section>


        </div>
    );
}