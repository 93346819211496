import {useContext, useEffect, useRef, useState} from "react";
import HeaderService from "../functionals/services/Service";
import {Link, useNavigate} from "react-router-dom";
import ApplicationRoutes from "../../../environment/AppRoutingName";
import UserRoles from "../functionals/models/UserRoles";
import {FaHome, FaUserCircle} from "react-icons/fa";
import {IoClose, IoLogOut, IoMegaphone} from "react-icons/io5";
import {IoIosBusiness} from "react-icons/io";
import {AuthContext} from "../../../functionals/authLogic/AuthContext";
import NotificationsService from "../../../../services/NotificationsService";
import NotificationModel from "../../../../models/NotificationModel";
import Loading from "../../loading/views/Loading";
import NotificationType from "../../../../models/NotifictionType";


export default function Header() {

    const {user, setUser} = useContext(AuthContext);
    const navigate = useNavigate();
    const [isOpenUser, setIsOpenUser] = useState(false);
    const [isNotificationsOpen, setIsNotificationsOpen] = useState<boolean>(false);
    const dropdownRefUser = useRef<HTMLDivElement>(null);
    const [notifications, setNotifications] = useState<NotificationModel[]>([]);
    const [isLoadingNotifications, setIsLoadingNotifications] = useState<boolean>(false);
    const notificationService = new NotificationsService();

    const [currentNotificationType, setCurrentNotificationType] = useState<NotificationType | null>(null);

    const toggleDropdownUser = () => {
        setIsOpenUser(!isOpenUser);
    };

    const closeDropdown = (event: MouseEvent) => {
        if (dropdownRefUser.current && !dropdownRefUser.current.contains(event.target as Node)) {
            setIsOpenUser(false);
        }
    };

    const tabs = [{
        name: "Toate",
        type: null,
    }];

    useEffect(() => {
        document.addEventListener('click', closeDropdown);

        return () => {
            document.removeEventListener('click', closeDropdown);
        };
    }, []);

    useEffect(() => {
        if (isNotificationsOpen)
            getAllNotifications();

    }, [isNotificationsOpen]);

    const getAllNotifications = () => {
        setIsLoadingNotifications(true);
        notificationService.getAllNotifications()
            .then((response) => {
                setNotifications(response)
            })
            .catch((_) => {
            })
            .finally(() => setIsLoadingNotifications(false));
    }

    function logout() {
        new HeaderService().logoutUser().then(_ => {
                setUser(null)
                setIsOpenUser(false);
                navigate(ApplicationRoutes.login);
            }
        );
    }

    function redirect(path: string) {
        setIsOpenUser(false);
        navigate(path);
    }

    const parseDate = (date: Date) => {
        return date.toLocaleDateString("ro-RO", {
            day: "2-digit",
            month: "short",
            year: "numeric",
        })
    }

    return (
        <div
            className={"fixed text-fullWhite z-20 text-xl transition-all flex flex-row items-center justify-between h-[60px] pr-[30px] py-2 w-full"}>
            <p></p>
            {
                user === null ?
                    <div>
                        <p className={"bg-accent-main-500 text-white cursor-pointer p-2 font-main-bold hover:shadow-lg hover:bg-white hover:text-accent-main-500 transition-all rounded-lg"}
                           onClick={() => redirect(ApplicationRoutes.login)}>
                            Portal logare
                        </p>
                    </div> :
                    <div>
                        <div className={"flex gap-5 text-sm"}>
                            <div
                                onClick={() => setIsNotificationsOpen(true)}
                                className="relative cursor-pointer bg-white border border-[#FFC72C] text-[#FFC72C] px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-[#FFC72C] hover:text-white transition"
                            >
                                {notifications.length === 0 && (
                                    <span className="absolute top-0 right-0 w-3 h-3 bg-red-500 rounded-full animate-ping"></span>
                                )}
                                {notifications.length === 0 && (
                                    <span className="absolute top-0 right-0 w-3 h-3 bg-red-500 rounded-full"></span>
                                )}
                                <IoMegaphone />
                                Notificari
                            </div>

                            <Link
                                to={"https://api.whatsapp.com/send?phone=40785225446&test=Salut&source=&data=&app_absent"}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="bg-white border border-[#25D366] text-[#25D366] px-4 py-2 rounded-lg flex items-center gap-2 hover:bg-[#25D366] hover:text-white transition"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    className="w-5 h-5"
                                >
                                    <path
                                        d="M12 0a12 12 0 0 0-10.3 17.9L0 24l6.4-1.7A12 12 0 1 0 12 0zm5.2 16.4c-.2.6-1.1 1-1.7 1.1-.4 0-.9.2-2.9-.6s-4.7-3-4.9-3.2c-.2-.2-1.1-1.5-1.1-2.9s.7-2.1 1-2.4c.2-.3.6-.3.8-.3h.6c.2 0 .4 0 .6.5s.8 2 .9 2.2c.1.2.2.5 0 .8s-.3.4-.5.7c-.2.2-.4.4-.2.7s1 1.6 2.2 2.2c1.5.8 2 .8 2.3.7.4-.3 1-1.2 1.2-1.6s.3-.4.6-.3.9.4 1.1.5.4.2.5.3c.2.1.2.5.1 1z"/>
                                </svg>
                                Suport
                            </Link>
                            {
                                user.role.name !== UserRoles.ROLE_ACCOUNT_ADMIN ?
                                    <div
                                        className="flex justify-center w-fit rounded-md border border-accent-main-500 shadow-sm px-4 py-2 bg-fullWhite text-sm font-main-medium text-accent-main-500">
                                        {user.role.name === UserRoles.ROLE_SUPER_ADMIN ? 'SUPER ADMIN' : user.firmName ?? 'Unknown SRL'}
                                    </div>
                                    :
                                    <div className="relative inline-block text-left">
                                        <div
                                            className="flex justify-center w-full rounded-md border border-accent-main-500 shadow-sm px-4 py-2 bg-fullWhite text-sm font-main-medium text-accent-main-500">
                                            <IoIosBusiness className={"w-5 h-5"}/>
                                            <p className={"ml-2"}>{user?.firmName ?? ''}</p>
                                        </div>
                                    </div>
                            }

                            <div className="relative inline-block text-left" ref={dropdownRefUser}>
                                <button
                                    onClick={toggleDropdownUser}
                                    type="button"
                                    className="flex justify-center w-full rounded-md border border-accent-main-500 shadow-sm px-4 py-2 bg-fullWhite text-sm font-main-medium text-accent-main-500">
                                    <FaUserCircle className={"w-5 h-5"}/>
                                    <p className={"pl-2"}>{user?.username ?? 'Unnamed User'}</p>
                                </button>

                                {isOpenUser && (
                                    <div
                                        className="origin-top-right absolute right-0 mt-2 w-[153px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                        <div className="py-1" role="menu" aria-orientation="vertical"
                                             aria-labelledby="options-menu">
                                            <div
                                                className="cursor-pointer flex justify-start px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                                role="menuitem"
                                                onClick={() => redirect(ApplicationRoutes.dashboard)}>
                                                <FaHome className={"w-5 h-5"}/>
                                                <p className={"pl-2"}>Home</p>
                                            </div>
                                            <div
                                                className="cursor-pointer flex justify-start px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                                role="menuitem"
                                                onClick={logout}>
                                                <IoLogOut className={"w-5 h-5"}/>
                                                <p className={"pl-2"}>Log out</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
            }
            {
                isNotificationsOpen
                    ?
                    <div
                        onClick={() => setIsNotificationsOpen(false)}
                        className="w-screen z-50 h-screen bg-black bg-opacity-50 inset-0 fixed flex justify-end items-end p-4 pt-[70px]">
                        <div onClick={(event) => event.stopPropagation()}
                             className="bg-white w-1/3 h-full rounded-lg relative overflow-hidden">
                            <div className={"pt-2 pr-2 flex justify-end items-end"}>
                                <button className="text-error" onClick={() => {
                                    setIsNotificationsOpen(false)
                                }}>
                                    <IoClose/>
                                </button>
                            </div>
                            <div className="w-full border-gray-300 sticky top-0 bg-white z-10 px-4">
                                <h2 className="text-3xl font-main-bold mb-5 text-black">Notificari</h2>
                                <div className="flex border-b border-gray-300">
                                    {tabs.map((tab) => (
                                        <button
                                            key={tab.name}
                                            onClick={() => setCurrentNotificationType(tab.type)}
                                            className={`px-4 py-2 -mb-[1px] text-sm ${currentNotificationType === tab.type ? 'text-accent-main-600 border-b-2 border-accent-main-500 font-semibold' : 'text-gray-600'} focus:outline-none`}
                                        >
                                            {tab.name}
                                        </button>
                                    ))}
                                </div>
                            </div>
                            <div className="w-full h-full overflow-y-auto bg-gray-50 p-4 pt-6">
                                {
                                    isLoadingNotifications ?
                                        <Loading color={"fill-accent-main-500"}/>
                                        : notifications.filter((notification) => currentNotificationType === null ? true : notification.notificationType === currentNotificationType).map((notification) => (
                                            <div
                                                className="shadow-lg w-full flex flex-row gap-2 p-2 bg-white mb-2 rounded-lg">
                                                <div className="w-10 h-10">
                                                    <img src="/icon.png" className="w-full h-full" alt="logo"/>
                                                </div>
                                                <div className="w-full">
                                                    <div className="font-bold text-black text-xl">
                                                        {notification.title}
                                                    </div>
                                                    <div className="text-gray-700 text-sm">
                                                        {parseDate(new Date(notification.createdAt))}
                                                    </div>
                                                    <div className="text-gray-700 text-sm">
                                                        {notification.description}
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                }
                            </div>
                        </div>
                    </div>
                    : <></>
            }
        </div>
    );
}