import React from 'react';
import {BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import {AiDocumentCheckerMetrics} from "../../../models/responses/AiDocumentCheckerMetrics";

interface MetricsProps {
    data: AiDocumentCheckerMetrics[];
};

const AiDocumentCheckerMetricsGraph: React.FC<MetricsProps> = ({data}) => {
    const groupedData: Record<string, Record<string, number>> = {};

    data.forEach(user => {
        user.metricsDetails.forEach(detail => {
            if (!groupedData[detail.first]) {
                groupedData[detail.first] = {};
            }
            groupedData[detail.first][user.userName] = Number(detail.second);
        });
    });

    const chartData = Object.entries(groupedData).map(([month, users]) => ({
        month,
        ...users
    }));

    return (
        <div className="p-4 bg-white shadow-lg rounded-2xl w-full">
            <h2 className="text-xl font-bold mb-4">AI Document Checker Metrics</h2>
            <ResponsiveContainer width="100%" height={400}>
                <BarChart data={chartData}>
                    <XAxis dataKey="month"/>
                    <YAxis/>
                    <Tooltip/>
                    <Legend/>
                    {data.map(user => (
                        <Bar key={user.userName} dataKey={user.userName} name={user.userName} fill={getRandomColor()}/>
                    ))}
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
}

export default AiDocumentCheckerMetricsGraph;

function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}
