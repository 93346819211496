import Loading from "../../../commons/component/loading/views/Loading";
import React, {useContext, useEffect, useState} from "react";
import SubscriptionUsage from "../../../models/SubscriptionUsage";
import {AuthContext} from "../../../commons/functionals/authLogic/AuthContext";
import FirmService from "../../../services/FirmService";
import {toast, Toaster} from "react-hot-toast";
import Employee from "../../../models/Employee";
import Generals from "../../../commons/component/generals/Generals";
import SubscriptionPaymentStatus from "../../../models/SubscriptionPaymentStatus";
import SubscriptionPaymentStatusEnum from "../../../models/SubscriptionPaymentStatusEnum";
import Pairs from "../../../commons/functionals/utils/Pairs";
import {AiDocumentCheckerMetrics} from "../../../models/responses/AiDocumentCheckerMetrics";
import AiDocumentCheckerMetricsGraph from "../containers/AiDocumentCheckerMetricsGraph";

export default function AiDocumentCheckerFirmAdminDashboard() {

    const {user} = useContext(AuthContext);
    const [usage, setUsage] = useState<SubscriptionUsage[]>([]);
    const [usagesLoading, setUsagesLoading] = useState<boolean>(false);
    const [userStatistics, setUserStatistics] = useState<boolean>(false);

    const [employeesUsingApp, setEmployeesUsingApp] = useState<Pairs<string, number>[]>([]);
    const [aiDocumentCheckerMetrics, setAiDocumentCheckerMetrics] = useState<AiDocumentCheckerMetrics[]>([]);
    const [employeesUsingAppRequest, setEmployeesUsingAppRequest] = useState<Pairs<string, number>[]>([]);
    const [employeesUsingAppLoading, setEmployeesUsingAppLoading] = useState<boolean>(false);
    const [addCreditsLimitForEmployee, setAddCreditsLimitForEmployee] = useState<Employee | null>(null);

    const [employees, setEmployees] = useState<Employee[]>([]);
    const [employeesLoading, setEmployeesLoading] = useState<boolean>(false);

    const [acceptanceEmployeeLoading, setAcceptanceEmployeeLoading] = useState<boolean>(false);

    const [isLoadingSubscriptionStatus, setIsLoadingSubscriptionStatus] = useState<boolean>(false);
    const [subscriptionStatus, setSubscriptionStatus] = useState<SubscriptionPaymentStatus | null>(null);
    const service = new FirmService();

    useEffect(() => {
        if (user === null)
            return;

        setIsLoadingSubscriptionStatus(true);
        service.getSubscriptionStatus(user.firmSlug, (process.env.REACT_APP_AI_DOCUMENT_CHECKER_SLUG ?? 'none'))
            .then(response => {
                setSubscriptionStatus(response);
                setIsLoadingSubscriptionStatus(false);
            })
            .catch((_) => {
            })

        setUsagesLoading(true);
        service.getFirmUsages(user.firmSlug)
            .then(response => {
                let rest = response.filter((el) => el.additionalParam === (process.env.REACT_APP_AI_DOCUMENT_CHECKER_SLUG ?? 'none'));
                setUsage(rest.length === 0 ? [] : rest[0].second);
            })
            .catch((_) => toast.error("Am intampinat o eroare la afisarea metricilor. Incearca mai tarziu"))
            .finally(() => setUsagesLoading(false));

        if (user?.email === "raresdragutu9@gmail.com") {
            setUserStatistics(true);
            service.getAiDocumentCheckerUsage(user.firmSlug, (process.env.REACT_APP_AI_DOCUMENT_CHECKER_SLUG ?? 'none'))
                .then(response => setAiDocumentCheckerMetrics(response))
                .catch((_) => toast.error("Am intampinat o eroare la afisarea metricilor. Incearca mai tarziu"))
                .finally(() => setUserStatistics(false));
        }

        setEmployeesUsingAppLoading(true);
        service.getEmployeesUsingApp(user.firmSlug, process.env.REACT_APP_AI_DOCUMENT_CHECKER_SLUG ?? 'none').then((response) => {
            setEmployeesUsingApp(response.filter((e) => e.first !== user.slug));
            setEmployeesUsingAppRequest(response.filter((e) => e.first !== user.slug));
        }).catch((_) => {
            toast.error("Am intampinat o eroare. Incarca din nou")
        }).finally(() => setEmployeesUsingAppLoading(false));

        setEmployeesLoading(true);
        service.getFirmEmployees(user.firmSlug, "").then((response) => {
            setEmployees(response.filter((e) => e.slug !== user.slug));
        }).catch((_) => {
            toast.error("Am intampinat o eroare. Incarca din nou")
        }).finally(() => setEmployeesLoading(false));
    }, []);

    const updateLimitForEmployeeAndApp = (data: Pairs<string, number>) => {
        setAcceptanceEmployeeLoading(true);
        service.updateUserAppAllowanceRate(user!.firmSlug, data.first, process.env.REACT_APP_AI_DOCUMENT_CHECKER_SLUG ?? 'none', data.second)
            .then((_) => {
                setEmployeesUsingApp(employeesUsingAppRequest);
                toast.success("Valoare salvata cu success");
            })
            .catch((_) => {
                toast.error("Am intampinat o eroare. Incearca mai tarziu.")
            })
            .finally(() => setAcceptanceEmployeeLoading(false));
    }

    const allowAppToUser = (userSlug: string) => {
        setAcceptanceEmployeeLoading(true);
        service.allowAppToUser(user!.firmSlug, userSlug, process.env.REACT_APP_AI_DOCUMENT_CHECKER_SLUG ?? 'none')
            .then((response) => {
                let currentUser = employees.filter((e) => e.slug === userSlug).map((u) => ({
                    first: u.slug,
                    second: 100
                }))[0];
                setEmployeesUsingApp([...employeesUsingApp, currentUser]);
                setEmployeesUsingAppRequest([...employeesUsingApp, currentUser]);
                toast.success(response);
            })
            .catch((error) => {
                toast.error(error.response.data ?? "Am intampinat o eroare. Incearca mai tarziu.")
            })
            .finally(() => setAcceptanceEmployeeLoading(false));
    }

    const disallowAppToUser = (userSlug: string) => {
        setAcceptanceEmployeeLoading(true);
        service.removeAppToUser(user!.firmSlug, userSlug, process.env.REACT_APP_AI_DOCUMENT_CHECKER_SLUG ?? 'none')
            .then((_) => {
                setEmployeesUsingApp(employeesUsingApp.filter((e) => e.first !== userSlug));
                setEmployeesUsingAppRequest(employeesUsingApp.filter((e) => e.first !== userSlug));
                toast.success("Utilizatorul nu mai are access");
            })
            .catch((_) => toast.error("Am intampinat o eroare. Incearca mai tarziu."))
            .finally(() => setAcceptanceEmployeeLoading(false));
    }

    return (
        <>
            <div><Toaster/></div>
            {
                !isLoadingSubscriptionStatus && subscriptionStatus?.subscriptionStatus === SubscriptionPaymentStatusEnum.expired ?
                    <div
                        className={"cursor-pointer p-2 mb-4 bg-red-600 text-white text-center w-full"}>
                        Abonamentul a expirat. Dupa 15 zile vor fi sterse toate datele tale automat.
                        Fa un transfer bancar, iar noi vom confirma comanda ta.
                    </div> : <></>
            }
            {
                !isLoadingSubscriptionStatus && subscriptionStatus?.subscriptionStatus === SubscriptionPaymentStatusEnum.notPaid ?
                    <div
                        className={"cursor-pointer p-2 mb-4 bg-red-600 text-white text-center w-full"}>
                        Mai ai
                        <span className={"font-bold px-2"}>
                            {Math.ceil((subscriptionStatus.timeUtilDeadline - new Date().getTime()) / (1000 * 60 * 60 * 24))}
                        </span>
                        zile pana la expirarea abonamentului.
                        Fa un transfer bancar, iar noi vom confirma comanda ta.
                    </div> : <></>
            }
            {
                !isLoadingSubscriptionStatus && subscriptionStatus?.subscriptionStatus === SubscriptionPaymentStatusEnum.paid ?
                    <div
                        className={"p-2 mb-4 bg-green-500 text-white text-center w-full"}>
                        Abonamentul este valabil pana la data de
                        <span className={"font-bold px-2"}>
                            {new Date(subscriptionStatus.timeUtilDeadline).toLocaleDateString()}
                        </span>
                    </div> : <></>
            }
            <div className={"flex mb-1 justify-between"}>
                <h2 className="text-2xl font-main-bold mr-3">Statistici curente pentru Document Checker:</h2>
            </div>
            <div className="flex flex-row gap-3 mb-4">
                {
                    usagesLoading ?
                        <Loading color={"fill-accent-main-500"}/> :
                        usage.length === 0 ?
                            <p>Nu s-a inregistrat nicio statistica</p> :
                            usage.map(usageRow =>
                                <div className={""}>
                                    <div
                                        className={"flex flex-row gap-4"}>
                                        <div className="bg-white p-4 rounded-md shadow-md">
                                            <h3 className="text-lg font-main-medium mb-2">{Generals.getSystemStatusDescription(usageRow.title)}</h3>
                                            {Generals.getSystemStatusCorrectDisplay(usageRow)}
                                        </div>
                                    </div>
                                </div>
                            )
                }
            </div>
            <div className="flex flex-row gap-3 mb-4">
                {
                    userStatistics ?
                        <Loading color={"fill-accent-main-500"}/> :
                        <AiDocumentCheckerMetricsGraph data={aiDocumentCheckerMetrics}/>
                }
            </div>
            <h2 className="text-2xl font-main-bold mr-3">Seteaza drepturi pentru utilizatorii:</h2>
            <div className={"mt-4"}>
                {
                    employeesLoading ?
                        <div role="status" className="max-w-sm animate-pulse">
                            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[330px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[300px] mb-2.5"></div>
                            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
                            <span className="sr-only">Loading...</span>
                        </div>
                        :
                        <div className="flex flex-col w-full gap-4">
                            {employees.map((employee, index) => (
                                <div key={index}
                                     className="bg-gray-200 w-full p-4 rounded-md flex justify-between items-center">
                                    <div>
                                        <p className="text-lg font-main-medium">{employee.username}</p>
                                        <p className="text-sm text-gray-600">{employee.email}</p>
                                    </div>
                                    {
                                        employeesUsingAppLoading ?
                                            <Loading color={"fill-accent-main-500"}/> :
                                            employeesUsingApp.map((e) => e.first).includes(employee.slug) ?
                                                <div className={"flex flex-row gap-5"}>
                                                    <button
                                                        onClick={() => setAddCreditsLimitForEmployee(employee)}
                                                        className="border border-accent-main-500 text-black hover:text-white bg-white hover:bg-accent-main-500 font-main-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2">
                                                        Limiteaza credite
                                                    </button>
                                                    <button type="button"
                                                            disabled={acceptanceEmployeeLoading}
                                                            className="text-white bg-error hover:bg-red-500 font-main-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2"
                                                            onClick={() => disallowAppToUser(employee.slug)}>
                                                        {acceptanceEmployeeLoading ? <Loading/> : "Sterge acces"}
                                                    </button>
                                                </div>
                                                :
                                                <button type="button"
                                                        disabled={acceptanceEmployeeLoading}
                                                        className="text-white bg-accent-main-500 font-main-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2"
                                                        onClick={() => allowAppToUser(employee.slug)}>
                                                    {acceptanceEmployeeLoading ? <Loading/> : "Acorda acces"}
                                                </button>
                                    }
                                </div>
                            ))}
                        </div>
                }
            </div>
            {
                addCreditsLimitForEmployee === null
                    ? <></>
                    :
                    <div className={"z-20 w-screen h-screen inset-0 fixed bg-black bg-opacity-50"}>
                        <div className={"w-full h-full flex justify-center items-center"}>
                            <div
                                className={"w-1/3 h-fit p-6 rounded-lg bg-white flex flex-col justify-center items-center gap-4"}>
                                <h1>Care este limia de credite pentru: {addCreditsLimitForEmployee.username}</h1>
                                <input
                                    className={"p-2 rounded-lg border outline-none"}
                                    min={employeesUsingApp.filter((e) => e.first === addCreditsLimitForEmployee.slug)[0].second}
                                    value={employeesUsingAppRequest.filter((e) => e.first === addCreditsLimitForEmployee.slug)[0].second}
                                    onChange={(e) => {
                                        const newValue = parseInt(e.target.value, 10);
                                        setEmployeesUsingAppRequest((prev) =>
                                            prev.map((app) =>
                                                app.first === addCreditsLimitForEmployee.slug
                                                    ? {...app, second: newValue}
                                                    : app
                                            )
                                        );
                                    }}
                                    type={"number"}/>
                                <div>
                                    <button
                                        className="text-white bg-error font-main-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2"
                                        onClick={() => setAddCreditsLimitForEmployee(null)}>
                                        Anuleaza
                                    </button>
                                    <button
                                        disabled={acceptanceEmployeeLoading}
                                        className="text-white bg-accent-main-500 font-main-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2"
                                        onClick={() => updateLimitForEmployeeAndApp(employeesUsingAppRequest.filter((e) => e.first === addCreditsLimitForEmployee.slug)[0])}>
                                        {acceptanceEmployeeLoading ? <Loading/> : "Salveaza"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    );
}