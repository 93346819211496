import React from "react";
import {Link, Route, Routes} from "react-router-dom";
import {FaHome, FaMoneyCheck} from "react-icons/fa";
import {FaUsersBetweenLines} from "react-icons/fa6";
import DashboardUserRequestsPage from "../functionals/components/DashboardUserRequestsPage";
import DashboardAdminRouter from "../../../routers/DashboardAdminRouter";
import DashboardUsersPage from "../functionals/components/DashboardUsersPage";
import DashboardFirmsPage from "../functionals/components/DashboardFirmsPage";
import ApplicationRoutes from "../../../commons/environment/AppRoutingName";
import PrivateAccessRoute from "../../../commons/functionals/authLogic/route_permitters/PrivateAccessRoute";
import UserRoles from "../../../commons/component/header/functionals/models/UserRoles";
import DashboardNotificationPage from "../functionals/components/DashboardNotificationPage";

export default function DashboardAdminPageRoot() {


    const baseLink = ApplicationRoutes.dashboardAdminRoot;

    return (
        <div className={"pt-[50px] flex-col md:flex-row flex h-screen"}>
            <aside id="default-sidebar"
                   className="fixed w-64 h-screen transition-transform -translate-x-full sm:translate-x-0"
                   aria-label="Sidebar">
                <div className="h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-gray-800">
                    <ul className="space-y-2 font-medium">
                        <li>
                            <Link to={baseLink + DashboardAdminRouter.root}
                                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                <FaHome className={"w-5 h-5"}/>
                                <p className={"ms-3"}>User register requests</p>
                            </Link>
                        </li>
                        <li>
                            <Link to={baseLink + DashboardAdminRouter.allUsers}
                                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                <FaMoneyCheck className={"w-5 h-5"}/>
                                <p className={"ms-3"}>All users</p>
                            </Link>
                        </li>
                        <li>
                            <Link to={baseLink + DashboardAdminRouter.allFirms}
                                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                <FaUsersBetweenLines className={"w-5 h-5"}/>
                                <p className={"ms-3"}>All Firms</p>
                            </Link>
                        </li>
                        <li>
                            <Link to={baseLink + DashboardAdminRouter.notifications}
                                  className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                                <FaUsersBetweenLines className={"w-5 h-5"}/>
                                <p className={"ms-3"}>Create notification</p>
                            </Link>
                        </li>
                    </ul>
                </div>
            </aside>
            <div className="p-4 sm:ml-64 w-full">
                <Routes>
                    <Route path={DashboardAdminRouter.root} element={
                        <PrivateAccessRoute permittedApplicationAccess={"*"}
                                            permittedRole={[UserRoles.ROLE_SUPER_ADMIN]}>
                            <DashboardUserRequestsPage/>
                        </PrivateAccessRoute>
                    }/>
                    <Route path={DashboardAdminRouter.notifications} element={
                        <PrivateAccessRoute permittedApplicationAccess={"*"}
                                            permittedRole={[UserRoles.ROLE_SUPER_ADMIN]}>
                            <DashboardNotificationPage/>
                        </PrivateAccessRoute>
                    }/>
                    <Route path={DashboardAdminRouter.allUsers} element={
                        <PrivateAccessRoute permittedApplicationAccess={"*"}
                                            permittedRole={[UserRoles.ROLE_SUPER_ADMIN]}>
                            <DashboardUsersPage/>
                        </PrivateAccessRoute>
                    }/>
                    <Route path={DashboardAdminRouter.allFirms} element={
                        <PrivateAccessRoute permittedApplicationAccess={"*"}
                                            permittedRole={[UserRoles.ROLE_SUPER_ADMIN]}>
                            <DashboardFirmsPage/>
                        </PrivateAccessRoute>
                    }/>
                </Routes>
            </div>
        </div>
    )
}