import React, {useState} from "react";
import AdminService from "../../../../services/AdminService";
import CreateNotificationRequest from "../../../../models/requests/CreateNotificationRequest";
import {toast} from "react-hot-toast";

export default function DashboardNotificationPage() {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const adminService = new AdminService();
    const [createNotificationRequest, setCreateNotificationRequest] = useState<CreateNotificationRequest>({
        title: "",
        body: "",
        type: "GENERAL"
    });

    const createNotificaiton = () => {
        setIsLoading(true);
        adminService.createNotification(createNotificationRequest)
            .then((res) => toast.success("Successfully created"))
            .catch((_) => toast.error("Error creating notification request"))
            .finally(() => setIsLoading(false));
    }

    return (
        <>
            <h1 className={"my-4 text-2xl font-semibold"}>Send notification:</h1>
            <div className={"p-4"}>
                <input type={"text"} className={"p-2 w-full rounded-xl outline-none border"}
                       onChange={(e) => setCreateNotificationRequest((prev) => ({
                           ...prev,
                           title: e.target.value,
                       }))}/>
                <textarea className={"p-2 w-full rounded-xl outline-none border"} rows={5}
                          onChange={(e) => setCreateNotificationRequest((prev) => ({
                              ...prev,
                              body: e.target.value,
                          }))}/>
                <button disabled={isLoading} className={"text-white p-2 bg-accent-main-500 rounded-xl"}
                        onClick={createNotificaiton}>
                    Timite nofiticare
                </button>
            </div>
        </>
    )
}