import {AxiosResponse} from "axios";
import axiosInstance from "../commons/functionals/authLogic/AxiosInterceptors";
import {apiRoutes} from "../commons/environment/EnvironmentRoutes";
import NotificationModel from "../models/NotificationModel";

export default class NotificationsService {

    async getAllNotifications(): Promise<NotificationModel[]> {
        const response: AxiosResponse<NotificationModel[]> = await axiosInstance.get<NotificationModel[]>(apiRoutes.allNotifications);
        return response.data;
    }

}