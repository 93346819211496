import React, {useContext} from "react";
import {AuthContext} from "../../../../commons/functionals/authLogic/AuthContext";
import {IoClose, IoWarning} from "react-icons/io5";

export default function DashboardPage() {

    const {user} = useContext(AuthContext);

    return (
        <>
            <div className="p-4 w-full">
                <div
                    className="w-full bg-red-50 border border-red-200 rounded-2xl p-4 flex items-start shadow-md relative">
                    <div className="w-12 h-12 flex items-center justify-center bg-red-100 rounded-full mr-4">
                        <IoWarning className="text-red-600 text-2xl"/>
                    </div>
                    <div className="flex-1">
                        <h4 className="text-lg font-bold text-gray-800">Atenție: Verificare Suplimentară Recomandată</h4>
                        <p className="text-gray-600 text-sm">
                            Inovia are acuratețe de 75% în detectarea documentelor alterate, dar nu oferă un răspuns cert. Recomandăm verificarea
                            documentelor și la emitent pentru confirmare. Inovia pune la dispoziție informații, însă
                            decizia și responsabilitatea aparțin utilizatorilor.
                        </p>
                    </div>
                    <button
                        className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
                    >
                        <IoClose className="text-xl"/>
                    </button>
                </div>
                <h1 className={"pt-8 px-8 text-4xl font-main-bold"}>Bine ai venit, {user?.username}!</h1>
            </div>
        </>
    )
}